export default [
    {
        src: '/landing/companies/MOI.png',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/ARAMCO.png',
        alt: 'ARAMCO'
    },
    {
        src: '/landing/companies/SNB.png',
        alt: 'Saudi National Bank'
    },
    {
        src: '/landing/companies/PIF.png',
        alt: 'Public Investment Fund'
    },
    {
        src: '/landing/companies/AC.png',
        alt: 'Albilad Capital'
    },
    {
        src: '/landing/companies/GOFSI.png',
        alt: 'General Organization for Social Insurance'
    },
    {
        src: '/landing/companies/SDB.png',
        alt: 'Social Development Bank'
    },
    {
        src: '/landing/companies/MOBILY.png',
        alt: 'Mobily'
    },
    {
        src: '/landing/companies/MOEP.png',
        alt: 'Ministry Of Economy & Planning'
    },
    {
        src: '/landing/companies/MOHU.png',
        alt: 'Ministry Of Hajj And Umrah'
    },
    {
        src: '/landing/companies/IMI.png',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/KPMG.png',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/NEC.png',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/GAFC.png',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/DERAAH.png',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/K&A.png',
        alt: 'K & A'
    },
    {
        src: '/landing/companies/KS_RDA.png',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/NOVA.png',
        alt: 'Minister Of Investment'
    },
    {
        src: '/landing/companies/SCTC.png',
        alt: 'Communication, Space & Technology Commission'
    }
    //, {
    // src: '/landing/companies/ITTIHAD_CLUB.png',
    // alt: 'ITTIHAD CLUB'
    // },
]
