
export default {
    name: 'Drawer',
    props: {
        value: {
            type: Boolean,
            default: false
        }
    }
}
