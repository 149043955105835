
import SocialLinks from "@/components/landing/SocialLinks.vue";
import Drawer from "@/components/landing/Drawer.vue";
import Footer from "@/components/landing/Footer.vue";
import FlyoutMenu from "@/components/landing/FlyoutMenu.vue";
import Icon from '~/components/UntitledUI/Icon.vue'

export default {
    name: "LandingHeader",
    components: {FlyoutMenu, Footer, Drawer, SocialLinks, Icon},
    props: {
        source: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showDrawer: false,
            showIndustries: false,
            showProducts: false,
            showSubLandings: false,
        }
    }
}
