
import ClickOutside from 'vue-click-outside'

export default {
    name: 'FlyoutMenu',
    directives: {
        ClickOutside
    },
    data () {
        return {
            showFlyout: false
        }
    }
}
