
import SocialLinks from "@/components/landing/SocialLinks.vue";
import Footer from "@/components/landing/Footer.vue";

export default {
    name: "LandingFooter",
    components: {Footer, SocialLinks},
    props: {
        source: {
            type: String,
            default: null
        }
    }
}
