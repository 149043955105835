export const init = (parms) => {
  if(parms !== null){
    return event('init', process.env.SNAP_PIXEL, parms)
  }

  return event('init', process.env.SNAP_PIXEL)
}


export const event = (event, type, parms = {}) => {
  return snaptr(event, type, parms)
}
