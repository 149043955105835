
export default {
    name: 'AnimatedNumbers',
    props: {
        number: {
            type: Number,
            default: 0
        },
        play: {
            type: Boolean,
            default: false
        },
        duration: {
            type: Number,
            default: 3000
        },
        intervalBy: {
            type: Number,
            default: 30
        }
    },
    data () {
        return {
            displayNumber: 0,
            interval: false
        }
    },
    computed: {
        incrementBy () {
            return this.number / (this.duration / this.intervalBy)
        }
    },
    watch: {
        play (val) {
            if (val) {
                this.init()
            } else {
                clearInterval(this.interval)
                this.displayNumber = 0
            }
        }
    },
    mounted () {
        if (this.play) { this.init() }
    },
    beforeDestroy () {
        clearInterval(this.interval)
    },
    methods: {
        init () {
            clearInterval(this.interval)

            if (this.number === this.displayNumber) {
                return
            }
            this.interval = setInterval(() => {
                if (this.displayNumber !== this.number) {
                    this.displayNumber = this.displayNumber + this.incrementBy
                    if (this.displayNumber > this.number) {
                        this.displayNumber = this.number
                    }
                }
            }, this.intervalBy)
        }
    }
}
